import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Title } from '@siftscience/focus-components/text'
import { Button } from '@siftscience/focus-components/button'
import { Switch } from '@siftscience/focus-components/switch'
import { Rollback } from '@siftscience/focus-components/icons'
import AddRow from './add-row'
import ReasonCodesRow from './reason-codes-row'
import CustomRuleSetRow from './custom-rule-set-row'
import { TemplateDTO } from '../dto/template'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
    gap: '8px',
    height: '100%'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px'
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    height: '100%',
    border: '1px solid #E0E0E0',
    borderRadius: '6px',
    backgroundColor: '#FFFFFF'
  },
  contentArea: {
    paddingLeft: '12px',
    paddingTop: '6px'
  },
  actionButton: {
    display: 'flex',
    flexDirection: 'row'
  }
}))

interface TemplateEditorProps {
  title: string
  readonly?: boolean
  template: TemplateDTO
}

const TemplateEditor = ({ title, readonly, template }: TemplateEditorProps) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <Title size="medium">{title}</Title>
          {!readonly && <Switch />}
        </div>
        {!readonly && (
          <Button variant="secondary" className={classes.actionButton} lined>
            <Rollback />
            <Title size="xsmall">Revert to Default</Title>
          </Button>
        )}
      </div>
      <div className={classes.innerWrapper}>
        <div
          className={classes.contentArea}
          dangerouslySetInnerHTML={{ __html: template.content }}
        />
        <div>
          {!readonly && <AddRow />}
          <ReasonCodesRow readonly={readonly} />
          <CustomRuleSetRow />
        </div>
      </div>
    </div>
  )
}

export default TemplateEditor
