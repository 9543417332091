import React from 'react'
import { MerchantProvider } from './merchant-context'
import { ProcessorProvider } from './processor-context'
import { TemplateProvider } from './template-context'

// Combines all template-tooling-related providers
export const TemplateToolingProvider = ({ children }) => (
  <MerchantProvider>
    <ProcessorProvider>
      <TemplateProvider>{children}</TemplateProvider>
    </ProcessorProvider>
  </MerchantProvider>
)
