import axios from '../../../utils/http/axios-local'
import { TemplateDTO } from '../dto/template'

export const putTemplate = (
  templateId: number,
  merchantId: number,
  data: Partial<TemplateDTO>
) => {
  let url = `/det/tooling/templates/${templateId}`
  if (merchantId) {
    url += `?merchant_id=${merchantId}`
  }
  return axios.put(url, data)
}

export const putEvidenceRules = (
  ruleId: number,
  jsonLogicRules: unknown,
  reasonCodeCategories: string[]
) => {
  const data: any = {}
  const url = `/det/tooling/evidence_rules/${ruleId}`

  if (jsonLogicRules) {
    data.json_logic_ruleset = jsonLogicRules
  }

  if (reasonCodeCategories) {
    data.reason_code_categories = reasonCodeCategories
  }
  return axios.put(url, data)
}
