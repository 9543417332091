import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useTemplates } from '../hooks/useTemplate'
import { useMerchantContext } from './merchant-context'
import { useProcessorContext } from './processor-context'
import { EvidenceRule, TemplateDTO, TemplateType } from '../dto/template'
import { putEvidenceRules, putTemplate } from '../utils/template-utils'
import { useTemplate } from '../useTemplateTooling'

interface ITemplateContext {
  templateList?: TemplateDTO[]
  rootTemplates?: TemplateDTO[]
  activeTemplateData?: TemplateDTO
  templatesHash?: Map<number, TemplateDTO>
  activeTemplate?: number
  setActiveTemplate?: (id: number) => void
  isTemplatesLoading?: boolean
  searchValue: string
  setSearchValue: (value: string) => void
  selectTemplate: (id: number, parentList: string) => void
  parentList: string // list of template ids divided with '-'
  updateTemplate: (templateData: Partial<TemplateDTO>) => void
  updatedEvidenceRules: EvidenceRule
  setUpdatedEvidenceRules: (data: EvidenceRule) => void
  saveTemplateChanges: () => Promise<void>
}

const TemplateContext = createContext<ITemplateContext>({})

export const TemplateProvider = ({ children }) => {
  const { selectedMerchant } = useMerchantContext()
  const { selectedProcessor } = useProcessorContext()
  const { templatesData, isTemplatesLoading } = useTemplates(
    selectedMerchant?.id,
    selectedProcessor?.id
  )
  const [rootTemplates, setRootTemplates] = useState([])
  const [activeTemplate, setActiveTemplate] = useState<number | null>(null)
  const { templateData: activeTemplateData } = useTemplate(activeTemplate)
  const [parentList, setParentList] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [templatesHash, setTemplatesHash] = useState<Map<number, TemplateDTO>>(
    new Map()
  )
  const [updatedEvidenceRules, setUpdatedEvidenceRules] =
    useState<EvidenceRule | null>(null)

  useEffect(() => {
    if (templatesData) {
      const filteredTemplates = templatesData.filter(template => {
        return (
          !template.parentTemplateIds?.length ||
          template.templateType === TemplateType.configurationPanel
        )
      })
      setRootTemplates(filteredTemplates)
    } else {
      setRootTemplates([])
    }
  }, [templatesData])

  useEffect(() => {
    if (templatesData) {
      const hash = new Map()
      templatesData.forEach(template => {
        hash.set(template.templateId, template)
      })
      setTemplatesHash(hash)
    } else {
      setTemplatesHash(new Map())
    }
  }, [templatesData])

  useEffect(() => {
    setActiveTemplate(null)
  }, [templatesData])

  const selectTemplate = (id: number, parentList: string) => {
    setActiveTemplate(id)
    setParentList(parentList)
  }

  const updateTemplate = async (templateData: Partial<TemplateDTO>) => {
    const { data } = await putTemplate(
      activeTemplate,
      selectedMerchant?.id,
      templateData
    )
    // TODO finish during ticket for saving changes
  }

  const saveTemplateChanges = async () => {
    if (updatedEvidenceRules) {
      await putEvidenceRules(
        updatedEvidenceRules.id,
        updatedEvidenceRules.json_logic_ruleset,
        updatedEvidenceRules.reason_code_categories
      )
    }
  }

  const contextValue = useMemo(
    () => ({
      templateList: templatesData,
      isTemplatesLoading,
      rootTemplates,
      templatesHash,
      activeTemplate,
      selectTemplate,
      searchValue,
      setSearchValue,
      parentList,
      updateTemplate,
      activeTemplateData,
      updatedEvidenceRules,
      setUpdatedEvidenceRules,
      saveTemplateChanges
    }),
    [
      templatesData,
      isTemplatesLoading,
      rootTemplates,
      templatesHash,
      activeTemplate,
      selectTemplate,
      searchValue,
      setSearchValue,
      parentList,
      updateTemplate,
      activeTemplateData,
      updatedEvidenceRules,
      setUpdatedEvidenceRules,
      saveTemplateChanges
    ]
  )

  return (
    <TemplateContext.Provider value={contextValue}>
      {children}
    </TemplateContext.Provider>
  )
}

export const useTemplateContext = () => {
  return useContext(TemplateContext)
}
