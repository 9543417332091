import React, { useRef } from 'react'
import { Title } from '@siftscience/focus-components/text'
import { Button } from '@siftscience/focus-components/button'
import { AtEmail, NoIcon, ReturnLog } from '@siftscience/focus-components/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Popover } from '@siftscience/focus-components/popover'
import Action from './action'

const useStyles = makeStyles(() => ({
  actionBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'flex-end',
    width: '100%',
    padding: '8px 16px',
    borderTop: '1px solid #E0E0E0'
  },
  actionButton: {
    display: 'flex',
    flexDirection: 'row'
  },
  addActionsBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px'
  }
}))

const AddRow = () => {
  const popoverOnClickRef = useRef(null)
  const classes = useStyles()

  return (
    <div className={classes.actionBlock}>
      <Title size="xsmall">Add</Title>
      <div className={classes.addActionsBlock}>
        <Popover
          placement="top"
          content={() => (
            <Action
              defaultDataType="image"
              onCancel={event => {
                if (popoverOnClickRef.current) {
                  popoverOnClickRef.current(event)
                }
              }}
              onSave={() => {
                // TODO add validation that path has '$.evidences' prefix
                console.log('saved =)')
              }}
            />
          )}
        >
          {props => {
            popoverOnClickRef.current = props.onClick
            return (
              <Button
                variant="secondary"
                className={classes.actionButton}
                lined
                {...props}
              >
                <NoIcon />
                <Title size="xsmall">Image</Title>
              </Button>
            )
          }}
        </Popover>
        <Button variant="secondary" className={classes.actionButton} lined>
          <ReturnLog />
          <Title size="xsmall">Subtemplate</Title>
        </Button>
        <Button variant="secondary" className={classes.actionButton} lined>
          <AtEmail />
          <Title size="xsmall">Data Field</Title>
        </Button>
      </div>
    </div>
  )
}

export default AddRow
