import React from 'react'
import { Title } from '@siftscience/focus-components/text'
import { Button } from '@siftscience/focus-components/button'
import { Eye, Plus } from '@siftscience/focus-components/icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  actionBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'flex-end',
    width: '100%',
    padding: '8px 16px',
    borderTop: '1px solid #E0E0E0'
  },
  actionButton: {
    display: 'flex',
    flexDirection: 'row'
  }
}))

interface CustomRuleSetRowProps {
  readonly?: boolean
}

const CustomRuleSetRow = ({ readonly }: CustomRuleSetRowProps) => {
  const classes = useStyles()

  return (
    <div className={classes.actionBlock}>
      <Title size="xsmall">Custom Rule Set</Title>
      {readonly ? (
        <Button variant="secondary" className={classes.actionButton} lined>
          <Eye />
          <Title size="xsmall">Show Rule Set</Title>
        </Button>
      ) : (
        <Button variant="secondary" className={classes.actionButton} lined>
          <Plus />
          <Title size="xsmall">Add Rule Set</Title>
        </Button>
      )}
    </div>
  )
}

export default CustomRuleSetRow
